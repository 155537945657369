import React from "react";
import PropTypes from "prop-types";
import View from "components/auth/Login.view";
import { API_URL } from "settings";


const redirect_url = API_URL + "/users/azure_auth/"
const redirect_href = "https://login.microsoftonline.com/5a3c2a26-df13-4524-9df2-07d9a4d4a7d0/oauth2/v2.0/authorize?client_id=a2852a12-bbfc-48e0-b694-999138377929&response_type=code&response_mode=query&scope=openid%20User.Read&prompt=select_account&state=" + encodeURI("crz-link-" + redirect_url);

function Login() {
  return <View 
    redirect_href={redirect_href}
  />;
}

Login.propTypes = {};

export default Login;